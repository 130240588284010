.step-progress-content 
{
    border-radius: 5px;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.step-progress-label 
{
    color : #2f3e68;
}