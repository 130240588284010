.top-bar 
{
    border-bottom-style: solid;
    margin-bottom: 1%;
    padding: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    color : #2f3e68;
    border-bottom-width: thin;
}

#top-bar-img-div
{
    width: 7%;
    margin-right: 1%;
}
#top-bar-img-div img
{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
#top-bar-nav-items  
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 5%;
    font-size: larger;    
}

#top-bar-nav-items a
{    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    text-decoration: none;
    color : #2f3e68;
}

#top-bar-nav-items a:hover
{
    color: #b0b7d2;
}

#top-bar-nav-items button
{
    border-style: none;
    background-color: red;
    cursor: pointer;
    font-size: large;
    padding: 10px;
    border-radius: 5px;
    color: white;

}