#reports-page input
{
    display: block;
    margin: 0 auto;
    font-size: large;
    padding: 10px;
    width: 20%;
    border-radius: 5px;
    border-style: none;
    background-color: #7082e5;
    color: white;
}

#reports-page input::placeholder
{
    color: rgba(255, 255, 255, 0.513);
}

#reports-page table 
{
    margin: 0 auto;
    margin-top: 3%;
    width: 100%;
    border-collapse: collapse;
}