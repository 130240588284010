#report-item-tr 
{
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 8px;
    font-family: 'Times New Roman', Times, serif

}

#report-item-tr:hover 
{
    background-color: #ddd;
}
#report-item-tr:nth-child(even)
{
    background-color: #d8e2f7;
}
#report-item-tr:nth-child(even):hover
{
    background-color: #b8c9f7;
}

#report-item-tr td
{
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 10%;
      

}

#report-item-tr img 
{
    width: 80px;
    height: 80px;
    border-radius: 5px;
    cursor: pointer;
}

.report-item-button
{
    border-style: none;
    background-color: #3f51b5;
    cursor: pointer;
    color: white;
    padding: 5%;
    font-family: 'Open Sans', sans-serif;
    border-radius: 5px;
    margin-top: 3%;
}

#delete-button
{
    background-color: red;
}

.report-item-button a 
{
    text-decoration: none;
    color: white;
}