#login-page
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;

}

#login-page-div 
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: 50vh;
    border-radius: 20px;
    margin-top: -5%;
}
#login-page-div  img 
{
    width: 128px;
    margin-bottom: 5%;
}
#login-page form
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

#login-page form input 
{
    width:60%;
    margin-top: 5px;
    border-style: none;
    border-bottom-style: solid;
    background-color: transparent;
    padding: 10px;
    font-size: large;
    border-bottom-color: #dab8ab;

}

#login-page form button 
{
    width:30%;
    margin: 1%;
    border-style: none;
    margin-top: 3%;
    background-color: transparent;
    border-radius: 5px;
    padding: 10px;
    font-size: large;
    cursor: pointer;
    background-color: #dab8ab;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    border-radius: 5px;

}

#password-div 
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
}


#password-div span
{
    position: absolute;
    right: 0;
    margin-top: 5px;
    z-index: 20;
    margin-right: 35% ;
    cursor: pointer;
    color: red;
}