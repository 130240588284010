.page-container 
{
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    color : #2f3e68;
}

.page-container h1 
{
    color :#354470
}