#qr-code-div 
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#qr-code-img-div 
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#qr-code-img-div img 
{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

#qr-code-div button 
{
    width: 100%;
    height: 100%;
    border-style: none;
    background-color: #3f51b5;
    cursor: pointer;
    color: white;
    padding: 5%;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
    border-radius: 5px;
    margin-top: 3%;
}

#qr-code-div button:hover
{
    background-color: #293998;
}