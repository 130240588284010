.bottom-bar 
{
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    bottom: 0;
    z-index: -100;
    position: fixed;

}
.bottom-bar img 
{
    width: 40%;
    height: 100%;
    cursor: pointer;
    z-index: -10;
}
