#get-file-form
{
    display: block;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    color : #2f3e68;


}
#get-file-form p {
    font-family: 'Open Sans', sans-serif;
    color: #4c5e8b;
}

#get-file-form input 
{
    display: block;
    margin: 0 auto;
    margin-top: 5%;
    margin-bottom: 5%;
}

#get-file-form button 
{
    margin-bottom: 5%;
    margin-top: 5%;
    border-style: none;
    background-color: red;
    cursor: pointer;
    color: white;
    padding: 2%;
    font-family: 'Open Sans', sans-serif;
    border-radius: 5px;
    font-size: 1.2em;
}